var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "selection-box": true,
        "selection-box--standalone": _vm.standalone,
        "selection-box--plm": _vm.plm,
      },
    },
    [
      _c("label", { staticClass: "selection-box__container" }, [
        _vm._type === "checkbox"
          ? _c(
              "input",
              _vm._g(
                _vm._b(
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.localChecked,
                        expression: "localChecked",
                      },
                    ],
                    ref: "input",
                    staticClass: "selection-box__input",
                    attrs: { id: _vm._id, name: _vm.name, type: "checkbox" },
                    domProps: {
                      value: _vm._value,
                      checked: Array.isArray(_vm.localChecked)
                        ? _vm._i(_vm.localChecked, _vm._value) > -1
                        : _vm.localChecked,
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$a = _vm.localChecked,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = _vm._value,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.localChecked = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.localChecked = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.localChecked = $$c
                          }
                        },
                        _vm.emitCheckboxState,
                      ],
                    },
                  },
                  "input",
                  _vm.$attrs,
                  false
                ),
                _vm.$listeners
              )
            )
          : _vm._type === "radio"
          ? _c(
              "input",
              _vm._g(
                _vm._b(
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.localChecked,
                        expression: "localChecked",
                      },
                    ],
                    ref: "input",
                    staticClass: "selection-box__input",
                    attrs: { id: _vm._id, name: _vm.name, type: "radio" },
                    domProps: {
                      value: _vm._value,
                      checked: _vm._q(_vm.localChecked, _vm._value),
                    },
                    on: {
                      change: [
                        function ($event) {
                          _vm.localChecked = _vm._value
                        },
                        _vm.emitCheckboxState,
                      ],
                    },
                  },
                  "input",
                  _vm.$attrs,
                  false
                ),
                _vm.$listeners
              )
            )
          : _c(
              "input",
              _vm._g(
                _vm._b(
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.localChecked,
                        expression: "localChecked",
                      },
                    ],
                    ref: "input",
                    staticClass: "selection-box__input",
                    attrs: { id: _vm._id, name: _vm.name, type: _vm._type },
                    domProps: { value: _vm._value, value: _vm.localChecked },
                    on: {
                      change: _vm.emitCheckboxState,
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.localChecked = $event.target.value
                      },
                    },
                  },
                  "input",
                  _vm.$attrs,
                  false
                ),
                _vm.$listeners
              )
            ),
        _vm._v(" "),
        _c(
          "span",
          {
            class: {
              "selection-box__background": true,
              "selection-box__background--large": _vm.large,
              "selection-box__background--radio": this._type === "radio",
            },
          },
          [
            _c(
              "svg",
              {
                staticClass: "selection-box__checkmark",
                attrs: { viewBox: "0 0 24 24" },
              },
              [
                _c("path", {
                  staticClass: "selection-box__checkmark-path",
                  attrs: {
                    fill: "none",
                    d: "M1.73,12.91 8.1,19.28 22.79,4.59",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _vm.radio
              ? _c("span", { staticClass: "selection-box__radio-dot" })
              : _vm._e(),
            _vm._v(" "),
            _c("span", { staticClass: "selection-box__mixed-mark" }),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.label
        ? _c(
            "label",
            { staticClass: "selection-box__label", attrs: { for: _vm._id } },
            [_vm._v(_vm._s(_vm.label))]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }