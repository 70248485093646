<template>
  <div :class="{ 'selection-box': true, 'selection-box--standalone': standalone, 'selection-box--plm': plm }">
    <label class="selection-box__container">
      <input
        class="selection-box__input"
        :id="_id"
        :name="name"
        :type="_type"
        v-model="localChecked"
        :value="_value"
        v-bind="$attrs"
        v-on="$listeners"
        ref="input"
        @change="emitCheckboxState"
      />
      <span
        :class="{
          'selection-box__background': true,
          'selection-box__background--large': large,
          'selection-box__background--radio': this._type === 'radio',
        }"
      >
        <svg class="selection-box__checkmark" viewBox="0 0 24 24">
          <path class="selection-box__checkmark-path" fill="none" d="M1.73,12.91 8.1,19.28 22.79,4.59" />
        </svg>
        <span v-if="radio" class="selection-box__radio-dot"></span>
        <span class="selection-box__mixed-mark"></span>
      </span> </label
    ><!--
    -->
    <label v-if="label" class="selection-box__label" :for="_id">{{ label }}</label>
  </div>
</template>

<script>
import twoWayProperty from 'apps/public/lib/twoWayProperty';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'custom-checkbox',

  model: {
    prop: 'checked',
    event: 'update:checked',
  },

  props: {
    checked: {},
    id: {},
    label: {},
    large: { type: Boolean, default: false },
    name: {},
    plm: { type: Boolean, default: false },
    standalone: { type: Boolean, default: false },
    type: {},
    value: {},
    indeterminate: Boolean,
  },

  data() {
    return {};
  },

  computed: {
    localChecked: twoWayProperty('checked.checked', { fallback: 'checked' }),
    _id() {
      return this.id || (this.name || '') + '-' + uuidv4();
    },
    localIndeterminate: twoWayProperty('checked.indeterminate', { fallback: 'indeterminate' }),
    _type() {
      if (this.radio) return 'radio';

      return 'checkbox';
    },
    _value() {
      return this.value || (this.value === 0 ? 0 : this.label);
    },
    radio() {
      return this.type === 'radio';
    },
  },

  methods: {
    emitCheckboxState() {
      if (this.$refs.input.checked) {
        this.$emit('check');
      } else {
        this.$emit('uncheck');
      }
    },
  },

  watch: {
    localChecked: function () {
      this.localIndeterminate = false;
    },
    indeterminate: function (value) {
      this.$refs.input.indeterminate = value;
    },
    localIndeterminate: function (value) {
      this.$refs.input.indeterminate = value;
    },
  },
  mounted() {
    if (this.localIndeterminate) this.$refs.input.indeterminate = true;
  },
};
</script>

<style lang="scss">
@use 'sass:math';

// See ~common/styles/custom-checkbox.scss
</style>
